import { useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { TMessageDTO } from '../util/types'
import {
  useData,
  useJitsiLoader,
  useMessageQuery,
  useUserFetch,
} from '../util/hooks'
import config from '../config/config'
import { Box, Button, Grid, Link, Tooltip, Typography } from '@mui/material'
import BottomPanel from '../components/BottomPanel'
import ModeratorFreeTextEditor from '../components/ModeratorFreeTextEditor'
import ModeratorPredefinedTextEditor from '../components/ModeratorPredefinedTextEditor'
import MessageItem from '../components/MessageItem'
import '@fontsource/open-sans/400.css'
import FarewellMessage from '../components/FarewellMessage'
import Logo from '../components/Logo'
import CourtLogo from '../components/CourtLogo'
import { getJwt } from '../util/oidc'
import base32 from '../util/base32'

const NewJitsi = () => {
  const { location, room } = useParams()
  const [node, setNode] = useState<any>(null)

  //we need this for the landing-backend and the conference name to be the same.
  const conferenceIdentifier = base32
    .encode(`${location}_${room}`)
    .replaceAll('=', '')
  const { messages, isError: isMessageError } =
    useMessageQuery(conferenceIdentifier)
  const queryClient = useQueryClient()
  const [end, setEnd] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState(true)

  // Prevent leaving the page
  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
    }
    window.addEventListener('beforeunload', handler)
    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [])

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  // AUTH DATA
  const { user, isLoading: userLoading } = useUserFetch()
  const {
    data: jwt,
    isLoading: jwtLoading,
    isError: jwtError,
  } = useQuery(
    ['jwt', conferenceIdentifier],
    () => getJwt(conferenceIdentifier),
    {
      enabled: !!user,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      retryOnMount: false,
    }
  )

  const statuses = []
  if (isMessageError) {
    statuses.push('Message service unavailable')
  }

  const role = !!jwt ? 'host' : 'guest'

  if (config.debugEnableStatusMessages) {
    statuses.push(`location: ${location} `)
    statuses.push(`room: ${room} `)
    statuses.push(`preferred_username: ${user?.preferredUsername} `)
    statuses.push(`email: ${user?.email} `)
    statuses.push(`role: ${role} `)
    statuses.push(`jwt: ${jwt ? jwt : 'NO'} `)
  }

  const enabled = (): boolean => {
    if (jwtLoading || userLoading) return false
    if (user && (jwt || jwtError)) return true
    if (!user && !jwt) return true
    return false
  }

  const subject = `${room || ''}`.substring(0, 100)

  const preferredName =
    user?.surname && user?.court_role
      ? `${user.surname} (${user.court_role})`
      : user?.surname || ''
  const { isLoading, isError } = useJitsiLoader(config.jitsiFQDN, {
    enabled: enabled(),
    conferenceIdentifier: conferenceIdentifier,
    role: role,
    preferredName,
    room: room || '',
    location: location || '',
    lang: 'de',
    node: node,
    jwt,
    subject,
    email: user?.email || '',
    onEnd: () => {
      console.log('JITSI END CALLED')
      setEnd(true)
    },
  })

  const { mutate } = useMutation({
    mutationFn: (payload: { location: string; jwt: string; id: number }) => {
      const url = `${config.backendURL}/api/messages/${encodeURIComponent(
        payload.location
      )}/${payload.id}`
      const options: RequestInit = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
      return fetch(url, options)
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(['messages', conferenceIdentifier])
    },
  })

  const arrow_left = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5 8.25 12l7.5-7.5"
      />
    </svg>
  )

  const arrow_right = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  )

  const handleDelete = (message: TMessageDTO): void => {
    if (!user) return
    if (!message?.id) return

    mutate({
      id: message.id,
      jwt: jwt || '',
      location: conferenceIdentifier,
    })
  }

  const { data } = useData()

  const loc = useMemo(() => {
    if (!data) return null
    if (!location) return null
    return data.find(
      o => o.location_id.toLowerCase() === location.toLowerCase()
    )
  }, [location, data])

  const [messageTextField, setMessageTextField] = useState<string>('')
  const [forceRerender, setForceRerender] = useState<number>(0)
  const onMessageSelect = (text: string) => {
    setMessageTextField(text)
  }

  const handleSendMessage = () => {
    setForceRerender(forceRerender + 1)
    setMessageTextField('')
  }

  return (
    <Grid
      container
      direction="row"
      bgcolor={'black'}
      sx={{ minHeight: '100vh' }}
    >
      {isVisible ? (
        <div className="button-container extended">
          <div onClick={toggleVisibility}>
            {arrow_left}
            <Typography variant="caption">Einklappen</Typography>
          </div>
        </div>
      ) : (
        <Tooltip title="Ausklappen">
          <div className="button-container">
            <div onClick={toggleVisibility}>{arrow_right}</div>
          </div>
        </Tooltip>
      )}
      {/*INFO PANEL*/}

      {isVisible ? (
        <Grid item xs="auto" bgcolor={'white'}>
          <Box className={'jitsi-panel'}>
            <Box ml={2} mr={1} className={'jitsi-panel__header'}>
              <Box width={92}>
                <Logo logoWithoutText={true} fade={0} />
              </Box>
              <CourtLogo width={180} location={location} fade={0} />
            </Box>
            <Box ml={2} mr={1}>
              {loc?.name}
              <br></br>Mitteilung des Gerichts
            </Box>

            {/*info items*/}

            <Box className={'jitsi-panel__items'}>
              {/*moderator controls*/}

              {user && (
                <Box
                  className={
                    'jitsi-panel__items_item jitsi-panel__items_item--moderator'
                  }
                >
                  <Box sx={{ width: '100%' }}>
                    <ModeratorPredefinedTextEditor
                      key={'moderator-predefined-text-editor' + forceRerender}
                      onChange={onMessageSelect}
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <ModeratorFreeTextEditor
                      jwt={jwt}
                      conferenceIdentifier={conferenceIdentifier}
                      forceText={messageTextField}
                      onSend={handleSendMessage}
                    />
                  </Box>
                </Box>
              )}

              {!isMessageError &&
                messages &&
                messages.map(o => (
                  <MessageItem
                    key={'msg' + o.id}
                    message={o}
                    editable={!!user}
                    onDelete={handleDelete}
                  />
                ))}

              {statuses.map((i, idx) => (
                <MessageItem
                  key={'sysmsg-' + i}
                  message={{ message: i, id: idx }}
                  editable={false}
                />
              ))}
            </Box>
            <Box className={'jitsi-panel__faqs'}>
              <img
                alt="faqs"
                style={{ marginRight: `10px` }}
                src="/images/question-bold.svg"
              ></img>
              <Link className="text" underline="none" href={config.faqUrl}>
                Hilfe
              </Link>
            </Box>
            <Box className={'jitsi-panel__bottom'}>
              <BottomPanel directionColumn={true} />
            </Box>
          </Box>
        </Grid>
      ) : (
        ''
      )}

      {/*IFRAME*/}

      <Grid item xs sx={{ backgroundColor: 'white', width: '100%' }}>
        {isLoading && <h1 style={{ position: 'absolute' }}>Loading</h1>}

        {isError && (
          <h1 style={{ position: 'absolute' }}>
            Error loading Jitsi Web application.
          </h1>
        )}

        {end && <FarewellMessage location_id={location} room={room} />}

        {!end && <div className={'jitsi'} ref={setNode}></div>}
      </Grid>
    </Grid>
  )
}

export default NewJitsi
